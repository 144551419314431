﻿h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 100;
    line-height: 1.5;
    color: #7e161a;
}

h1{
    font-size: 2rem;
}

h2{
    font-size: 1.5rem;
}

h3{
    font-size: 1.4rem;
}

h4{
    font-size: 1.3rem;
}

h5{
    font-size: 1.1rem;
}

h6{
    font-size: 1.1rem;
}
