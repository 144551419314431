.jumbotron {
    background-size: cover;
    background-position: center;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    .display-4{
        color: #ffffff;
        @include media-breakpoint-down(md) {
            font-size: 2.5rem;
        };
        @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
        };
        @include media-breakpoint-down(xs) {
            font-size: 2rem;
        };
    }
}


#about-us{
    background-image: url('/img/jumbotron/jumbotron-bg-about-us.jpg');
}

#payment-methods{
    background-image: url('/img/jumbotron/jumbotron-bg-payment-methods.jpg');
}

#contact-us{
    background-image: url('/img/jumbotron/jumbotron-bg-contact-us.jpg');
}

#shipping{
    background-image: url('/img/jumbotron/jumbotron-bg-shipping.jpg');
}

#faq{
    background-image: url('/img/jumbotron/jumbotron-bg-faq.jpg');
}

#how-to-order{
    background-image: url('/img/jumbotron/jumbotron-bg-how-to-order.jpg');
}
