﻿footer {

    .footer-bottom {
        background: #7E161A none repeat scroll 0 0;
        color: #fff;
        font-size: 12px;
        padding: 20px 0;
    }

    .footer-middle {
        color: #666;
        line-height: 24px;
        padding: 20px 0;
        background-color: #000000;

        h4 {
            color: #fff;
            margin-bottom: 1em;
            font-size: 1.2em;
        }

        address {
            color: #ffffff;
        }

        ul {

            li {
                a {
                    color: #ffffff;
                }
            }
        }

        .footer-middle-logo {
            margin-bottom: 20px;
            max-width: 300px;

            @include media-breakpoint-down(md) {
                max-width: 200px;
            }
        }
    }
}