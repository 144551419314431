.quick-links {

    margin-bottom: 20px;

    .quick-links-link {
        max-width: 500px;

        @include media-breakpoint-down(md) {

            margin-bottom: 20px;
        };
        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        };
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        };

        img{
            width: 100%;
            height: 100%;
        }
    }
}
