.header-right-search {
    margin-top: 20px;

    @include media-breakpoint-down(sm){
        margin-left:10px;
        margin-top: 0px;
    }

    .header-right-search-button {
        height: 50px;
        width: 50px;
        background-image: url('/img/search-solid.svg') !important;
        background: #7e161a;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;

        &:hover{
            background-position: right 15px center !important;
            background-color: #dd4840;
        }

    }

    .header-right-search-form {
        margin-top: 10px;
        height: 50px;
    }

.autocomplete {
    /*the container must be positioned relative:*/

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;

    .item {
        padding: 5px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
        padding-left:10px;
      }

      .item:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
      }

      .active {
        /*when navigating through the items using the arrow keys:*/
        background-color: DodgerBlue !important;
        color: #ffffff;
      }
  }
}

}
