.product-grid {

    .product-grid-product {
        //border: 1px solid #dddddd;
        //background-color: #ffffff;
        margin-bottom: 15px;
        max-width: 300px;

        .product-rating-number{
            font-size: 1.5em;
            text-align: center;
        }

        img {
            display: block;
            //margin-left: auto;
            margin-right: auto;
        }
        //
        //.product-grid-product-logo {
        //    margin-top: 5px;
        //    margin-bottom: 5px;
        //    margin-left: 5px;
        //
        //    img {
        //        display: block;
        //        margin-left: auto;
        //        margin-right: auto;
        //    }
        //}

        .product-grid-product-info {
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px;

            .product-grid-product-info-condition {
                text-align: left;
                font-size: 0.8em;
                display: block;
                color: #707070;

            }

            .product-grid-product-info-title {
                text-align: left;
                font-size: 1em;
                display: block;

                a{
                    color: #000;
                }

                .product-grid-product-info-title-manufacturer{
                    a {
                        font-size: 0.8em;
                        color: #828282;
                    }
                }

                .product-grid-product-info-title-scale{
                    a {
                        font-size: 0.9em;
                        color: #00A089;
                    }
                }
            }
        }

        .product-grid-product-order {

            .product-grid-product-order-price {
                text-align: left;
                font-size: 1.5em;
                display: block;
                color: #ff4947;

                @include media-breakpoint-down(md) {
                    font-size: 1.3em;
                };

                .product-grid-product-order-price-sale {
                    color: #000000;
                    font-size: 0.7em;
                    text-decoration: line-through;
                }

            }

            .product-grid-product-order-delivery-highlight{
                font-size: 0.8em;
                display: inline-block;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin-bottom: .25rem;
                margin-top: .25rem;
                font-weight: 600;
                color: #01982b;
                border: 1px solid currentColor;
            }

            .product-grid-product-order-sold-out-highlight{
                font-size: 0.8em;
                display: inline-block;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin-bottom: .25rem;
                margin-top: .25rem;
                font-weight: 600;
                color: #ff4947;
                border: 1px solid currentColor;
            }

        }

        .product-grid-product-stars {
            background: url('/img/stars.png') no-repeat;
            height: 13px;
            width: 75px;
            margin: 0 auto;
            display: inline-block;
        }

        .product-grid-product-review {
            display: inline-block;
            font-size: 0.8em;
        }

        .product-grid-product-order {

            .product-grid-product-order-price {
                text-align: left;
                font-size: 1.5em;
                display: block;
                color: #ff4947;

                @include media-breakpoint-down(md) {
                    font-size: 1.3em;
                };

                .product-grid-product-order-price-sale {
                    color: #000000;
                    font-size: 0.7em;
                    text-decoration: line-through;
                }

                .product-grid-product-order-price-changes {
                    color: #9a9a9a;
                    font-size: 0.6em;
                }

                .product-grid-product-order-price-changes-positive {
                    color: #01982b;
                    font-size: 0.6em;
                }

            }

            .product-grid-product-order-price-small {
                text-align: left;
                font-size: 1.2em;
                display: block;
                color: #ff4947;

                @include media-breakpoint-down(md) {
                    font-size: 1.3em;
                };

                .product-grid-product-order-price-sale {
                    color: #000000;
                    font-size: 0.7em;
                    text-decoration: line-through;
                }

            }

            .product-grid-product-order-market-value{
                font-size: 0.8em;
            }

            .product-grid-ebay-order-available-prices{
                padding: 5px;
                color: #808080;

                .product-grid-ebay-order-available-prices-prices{
                    font-size: 0.8em;
                    font-style: italic;
                    a {
                        color: #3490dc;
                    }
                }

                .product-grid-ebay-order-available-prices-count{
                    font-size: 0.8em;
                }

            }

            .product-grid-product-order-market-count{
                font-size: 0.8em;
            }

            .product-grid-product-order-delivery-highlight{
                font-size: 0.8em;
                display: inline-block;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin-bottom: .25rem;
                margin-top: .25rem;
                font-weight: 600;
                color: #01982b;
                border: 1px solid currentColor;
            }

            .product-grid-product-order-sold-out-highlight{
                font-size: 0.8em;
                display: inline-block;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin-bottom: .25rem;
                margin-top: .25rem;
                font-weight: 600;
                color: #ff4947;
                border: 1px solid currentColor;
            }
            .product-order-order-button{

                .add-to-cart{
                    background-color: #ffcd00;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                // .visit-ebay{
                //     background-color: #3490dc;
                //     color: #ffffff;
                //     padding-left: 15px;
                //     padding-right: 40px;
                //     padding-top: 5px;
                //     padding-bottom: 5px;
                // }

            }

            .product-grid-product-deals {

                //border-style: solid;
                padding: 10px;
                //border-color: #38c172;
                background-color: #f7f7f9;
                // padding: 0;
                // border-color: #ffffff;
                //border-radius: 10px;

                .product-grid-product-deals-price {
                    text-align: left;
                    font-size: 1.5em;
                    display: block;
                    color: #ff4947;

                    @include media-breakpoint-down(md) {
                        font-size: 1.3em;
                    };

                    .product-grid-product-deals-price-small-dollar{
                        font-size: 0.75em;
                        color: #000000;
                        display: block;
                    }

                    .product-grid-product-deals-price-changes {
                        color: #9a9a9a;
                        font-size: 0.6em;
                    }

                    .product-grid-product-deals-price-changes-positive {
                        color: #01982b;
                        font-size: 0.6em;
                    }

                }

                .product-grid-product-deals-price-small {
                    text-align: left;
                    font-size: 1.2em;
                    display: block;
                    color: #ff4947;

                    @include media-breakpoint-down(md) {
                        font-size: 1.3em;
                    };

                    .product-grid-product-deals-price-small-dollar{
                        font-size: 0.8em;
                        color: #000000;
                        display: block;
                    }

                    .product-grid-product-deals-price-changes {
                        color: #9a9a9a;
                        font-size: 0.6em;
                    }

                    .product-grid-product-deals-price-changes-positive {
                        color: #01982b;
                        font-size: 0.6em;
                    }

                }


                .product-grid-product-deals-market-value{
                    font-size: 0.8em;
                }

                .product-grid-product-deals-button{
                    .btn-sm{
                        padding: 6px 40px 6px 10px;
                        font-size: 13px;
                        color: #FFFFFF;
                        //background-size: 15px !important;
                        border-radius: 10px !important;
                        box-shadow: 1px 1px 2px 1px #888888 !important;
				}
			}

			.product-grid-product-deals-available-prices{
				font-size: 0.8em;
				font-style: italic;
			}

			.product-grid-product-deals-available-count{
				font-size: 0.8em;
			}

			.product-grid-product-deals-market-count{
				font-size: 0.8em;
			}

			.product-grid-product-deals-delivery-highlight{
				font-size: 0.8em;
				display: inline-block;
				padding-top: 0.1rem;
				padding-bottom: 0.1rem;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: .25rem;
				margin-top: .25rem;
				font-weight: 600;
				color: #01982b;
				border: 1px solid currentColor;
				background-color: #FFFFFF;
			}

			.product-grid-product-deals-sold-out-highlight{
				font-size: 0.8em;
				display: inline-block;
				padding-top: 0.1rem;
				padding-bottom: 0.1rem;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: .25rem;
				margin-top: .25rem;
				font-weight: 600;
				color: #ff4947;
				border: 1px solid currentColor;
			}
			// .product-grid-product-ebay{

			//     .add-to-cart{
			//         background-color: #ffcd00;
			//         padding-left: 20px;
			//         padding-right: 20px;
			//     }

			// }

		}

	}
}
}
