.language-selector {
  list-style-type: none;
  color: #ffffff;
  font-size: 0.8em;
  float: left;
  clear: both;
  width: 100%;

  .dropdown-toggle {
    padding: 0.5em;
  }

  .dropdown-menu {
    background-color: #fff;
    border: 0;

    li {
      a {
        span {
          font-size: 0.8em;
        }
      }
    }
  }
}

.language-selector-mobile {
    list-style-type: none;
    color: #ffffff;
    font-size: 0.8em;

    .dropdown-menu {
      background-color: #dd4840;
      border: 0;

      li {
        a {
          span {
            font-size: 0.8em;
          }
        }
      }
    }
  }

