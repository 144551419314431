﻿.btn {
	box-shadow: none !important;
	text-shadow: none !important;
	border-radius: 0px !important;
	margin: 10px 0;
	display: inline-block;
	// font-family: @primaryFont !important;
	font-weight: 400 !important;
	background-size: 18px !important;
	background-repeat: no-repeat !important;
	background-position: right 15px center !important;

	&:hover {
		background-position: right 11px center !important;
	}

	&.btn-sm {
		padding: 10px 45px 10px 25px;
		font-size: 16px;
		background-size: 15px !important;
	}

	&.btn-md {
		padding: 15px 55px 15px 35px;
		font-size: 17px;
	}

	&.btn-lg {
		padding: 20px 55px 20px 35px;
		font-size: 17px;
	}

	&.btn-sm.left {
		background-image: url('/img/left-arrow.svg') !important;
		background-position: left 15px center !important;
		padding-left: 45px !important;
		padding-right: 15px !important;
	}
	&.btn-md.left {
		background-image: url('/img/left-arrow.svg') !important;
		background-position: left 15px center !important;
		padding-left: 55px !important;
		padding-right: 15px !important;
	}
	&.btn-lg.left {
		background-image: url('/img/left-arrow.svg') !important;
		background-position: left 15px center !important;
		padding-left: 55px !important;
		padding-right: 15px !important;
	}



	&.btn-default {
		border: 0px !important;
		// background-color: @secondaryColor;
		background-image: url('/img/right-arrow.svg');
		color: #fff;
		&:hover {
			// background-color: darken(@secondaryColor, 10%);
			color: #fff;
		}
	}

	&.btn-primary {
		border: 0px !important;
		// background-color: @blueColor;
		background-image: url('/img/right-arrow.svg') !important;
		color: #fff;
		&:hover {
			// background-color: lighten(@blueColor, 10%);
			color: #fff;
		}

	}

    &.btn-success {
		border: 0px !important;
		// background-color: @blueColor;
		background-image: url('/img/right-arrow.svg') !important;
		color: #fff;
		&:hover {
			// background-color: lighten(@blueColor, 10%);
			color: #fff;
		}

	}

	&.btn-secondary {
		// border: 1px solid @lightColor;
		background-color: #fff;
		background-image: url('/img/right-arrow-rose.svg') !important;
		color: #4D4D4D;

		&:hover {
			background-color: darken(#fff, 10%);
			color: #4D4D4D;
		}
	}
}
