.navbar {
  border: 0px;
  background-color: #7E161A;
  padding: 0px;

  @include media-breakpoint-down(md) {
    background-color: #000;
  }

  .navbar-toggler{
      border-color: #ffffff;
      color: #ffffff;
      .navbar-toggler-icon{
        color: #ffffff;
      }
  }

  .navbar-nav {

    .active {
        background-color: #dd4840;
        color: #fff !important;
          &:hover {
              color: #fff;
              background-color: #DD4840;
          }
    }

    .nav-item {

        .nav-link {
        padding-left: 25px;
        padding-right: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 0.9rem;

        span {
          font-size: 1em;
          margin-left: 5px;
          margin-right: 5px;
        }

        &:hover {
          color: #fff;
          background-color: #DD4840;
        }

      }

      .dropdown-menu {

        @include media-breakpoint-down(sm) {
          background-color: #ffffff;
        }

        li {

          a {
            padding-top: 5px;
            padding-bottom: 5px;

            @include media-breakpoint-down(sm) {
              padding-top: 10px;
              padding-bottom: 10px;
            }

          }
        }
      }
    }

    .yamm-fw {

      .dropdown-toggle {
        padding-left: 25px;
        padding-right: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        color: #9d9d9d;
        font-size: 0.9rem;
      }

      .open {

        a {
          background-color: #7E161A;
        }
      }


      .yamm-content {

        h3 {
          font-size: 1.1em;
          margin-bottom: 10px;
          margin-top: 10px;
          margin-left: 0px;

          a {
            padding: 7px;
            color: #7e161a;
            width: 100%;
            display: block;

            &:hover {
              color: #fff;
              background-color: #DD4840;
            }
          }

        }

        .menu-list {
            margin: 0;
            padding: 0;

            @include media-breakpoint-up(lg) {
                column-count: 3;
                -webkit-columns: 3;
                -moz-columns: 3;
                column-rule: 1px solid lightblue;
                column-width: 200px;
                -webkit-column-width: 200px;
                -moz-column-width: 200px;
                // -webkit-columns: 2;
                // -moz-columns: 2;
            }
            @include media-breakpoint-only(md){
                column-count: 3;
                -webkit-columns: 3;
                -moz-columns: 3;
                column-rule: 1px solid lightblue;
                column-width: 200px;
                -webkit-column-width: 200px;
                -moz-column-width: 200px;
            }
            @include media-breakpoint-only(sm){
                max-height: none;
                columns: auto;
            }
            @include media-breakpoint-only(xs){
                max-height: none;
                columns: auto;
            }

            div{
                list-style: none;
                font-size: 1em;
                padding: 0;
                margin: 0;

                a{
                    padding-left: 7px;
                    color: #7e161a;
                    width: 100%;

                    &:hover {
                        background-color: #fff;
                        color: #dd4840;
                      }
                }
            }

        }

        ul {
          margin-top: 0em;
          margin-bottom: 0em;

          li {

            a {
              padding: 5px;
              font-size: 0.9em;
              padding-left: 5px;
              width: 100%;
              display: block;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          background-color: #ffffff;
        }

      }
    }

    .open {

      .dropdown-toggle {
        background-color: #dd4840 !important;
        color: #ffffff !important;
      }
    }

    .active {

      .dropdown-toggle {
        background-color: #dd4840 !important;
        color: #ffffff !important;
      }
    }
  }

  .navbar-right {
    background-color: #7e161a;
    color: #ffffff;

    .dropdown-toggle {
      padding-left: 25px;
      padding-right: 15px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .navbar-right-login {
      min-width: 250px;
      padding: 10px 10px 0;

      @include media-breakpoint-down(sm) {
        background-color: #ffffff;
        padding-bottom: 10px;
      }


      table {
        tbody {
          tr {

            td {
              border-top: 0px;
              padding: 0px;
              padding-top: 5px;
              padding-bottom: 5px;
              font-weight: bold;
              font-size: 1em;
              color: #000000;

              .btn {
                padding: 7px;
                font-size: 1em;
                margin: 0px;
                width: 100%;
                color: #ffffff;
              }

              span {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .navbar-header {

    .navbar-toggle {
      margin-top: 20px;
    }

    .navbar-brand {
      padding: 0;
      margin-top: 15px;
      margin-left: 15px;

      .header-logo {
        width: 300px;
      }
    }

    .navbar-nav {
      margin-top: 15px;
      margin-bottom: 0;
      padding-bottom: 0;
      width: 100%;

      .cart {
        margin-right: 20px;

        a {
          margin: 0;
          padding: 10px;
          color: #ffffff;
          font-size: 1.2em;
        }
      }
    }

    .header-mobile-search {
      padding-left: 10px;
      padding-right: 10px;

      .header-right-search-button {
        height: 50px;
        width: 50px;
        background-image: url('/img/search-solid.svg') !important;
      }

      .header-right-search-form {
        margin-top: 10px;
        height: 50px;

        table {
          tbody {
            tr {
              td {
                border-top: 0px;
              }
            }
          }
        }
      }
    }

  }
}

#nav.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.open {

  .dropdown-toggle {
    background-color: #dd4840 !important;
    color: #ffffff !important;
  }
}
