﻿header {
    background-color: #000;
    .header {
        height: 100px;

        .header-logo {
            padding: 15px;;
            width: 300px;
        }

        .header-right {
            // margin-top: 30px;

            @include media-breakpoint-down(sm){
                margin-top: 0px;
            }

            .header-right-cart {
                float: left;
                clear: both;
                width: 100%;

                .header-right-cart-button {
                    width: 100%;
                    height: 50px;
                    padding: 0px;
                    color: #000000;
                    background-color: #ffffff;
                    border-style: solid !important;
                    border-width: 1px !important;
                    border-color: #cccccc !important;
                    max-width: 200px;

                    span {
                        font-size: 0.8em;
                    }
                }

                .dropdown-menu {
                    min-width: 300px;
                    padding: 10px 10px 0;
                    float: right;
                    font-size: 1em;

                    .cart-reviews {
                        font-weight: normal;
                        font-size: 0.9em;

                        span.stars-count {
                            background: url('/img/stars.png') no-repeat;
                            height: 13px;
                            width: 75px;
                            margin: 0 auto;
                            display: inline-block;
                        }
                    }

                    .header-right-cart-product-image {
                        width: 50px;
                    }

                    small {
                        font-size: 0.9em;
                        padding: 0;
                        margin: 0;
                        display: block;
                        width: 100%;
                    }
                }
            }

            .iconbox {
                margin-top: 35px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;

                .icon {
                    color: #fff;
                    text-align: center;
                    width: 75px;

                    .icon-figure {
                        font-size: 1.45rem;
                        line-height: 0.8em;

                        .icon-figure-cap {
                            font-size: 0.7rem;
                        }
                    }

                    a{
                        color: #ffffff;
                        &:hover{
                            text-decoration: none;
                            color: #e98387;
                        }
                    }
                }
            }

        }

        @include media-breakpoint-down(sm){
            margin-bottom: 125px;
        }
    }

}

.header-mobile-search-lang{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    width: 100%;
    .language-switch-mobile{
        background-color:none;
        border: 0;
        color: #ffffff;
    }
}

.header-mobile-upper-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    width: 100%;
    padding: 0.5em;

    .header-logo{
        width: 100%;
        height: 100%;
    }

    .navbar-cart {
        color: #ffffff;
        font-size: 1.3em;
    }

    .iconbox {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .icon {
            color: #fff;
            text-align: center;
            width: 75px;
            margin-top: 13px;

            .icon-figure {
                font-size: 1.35rem;
                line-height: 0.8em;
            }

            a{
                color: #ffffff;
            }
        }
        .navbar{
            margin-left: 10px;;
            color: #fff;
            text-align: center;
            width: 75px;
        }
    }
}

.header-payment{
    background-color: #fff;
}
